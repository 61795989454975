export function initializeGtag(trackingId) {
  // Убедитесь, что dataLayer определён
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    dataLayer.push(arguments);
  };

  // Инициализация gtag.js
  gtag('js', new Date());

  // Конфигурация с переданным Tracking ID
  gtag('config', trackingId);
}